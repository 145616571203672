import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Dash, Plus } from "react-bootstrap-icons";

const QuanityController = (props) => {
  const [quantity, setQuantity] = useState(props.productQuantity);

  useEffect(() => {
    props.currentQuantity(quantity);
  }, [quantity, props]);
  return (
    <div className={styles.controllerContainer}>
      <button onClick={() => setQuantity((prev) => {
        if(prev === 0) {
          return prev;
        } else {
          return prev - 1;
        }
      })}>
        <Dash />
      </button>
      <input type="number" value={quantity} />
      <button onClick={() => setQuantity((prev) => prev + 1)}>
        <Plus />
      </button>
    </div>
  );
};

export default QuanityController;
