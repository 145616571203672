import styled from "styled-components";

export const Text = styled.p((props) => ({
  fontSize: props.$size || "1.7rem",
  fontWeight: props.$fontWeight || 500,
  textAlign: props.$textAlign || "left",
  color: props.$color || "var(--secondaryColor)",
}));

export const ErrorText = styled.p((props) => ({
  fontSize: props.$size || "1.4rem",
  fontWeight: props.$fontWeight || 500,
  textAlign: props.$textAlign || "left",
  color: props.$color || "red",
  width: "100%",
}));

export const SectionTitle = styled.h2((props) => ({
  fontSize: props.$size || "3rem",
  lineHeight: props.$size + 1 || "4.6rem",
  fontWeight: 600,
  textAlign: props.$textAlign || "left",
  color: props.$color || "var(--secondaryColor)",
  "@media (max-width: 590px)": {
    fontSize: "3rem",
  },
}));

export const NavText = styled.li((props) => ({
  fontSize: props.$size || "1.4rem",
  color: props.$color || "var(--whiteColor)",
  fontWeight: 500,
  "@media (max-width: 590px)": {
    fontSize: "1.6rem",
  },
}));

export const Button = styled.button((props) => ({
  fontSize: props.$size || "1.7rem",
  fontWeight: props.$weight || 700,
  width: props.$width || "auto",
  padding: props.$padding || "1.5rem 3rem",
  backgroundColor: props.$backgroundColor || "var(--primaryColor)",
  color: props.$color || "var(--whiteColor)",
  borderRadius: props.$borderRadius || "6rem",
  outline: "none",
  border: props.$border || "0.2rem solid var(--primaryColor)",
  transition: "all 0.3s ease-in-out",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  "&:hover": {
    backgroundColor: "transparent",
    color: "var(--primaryColor)",
  },
}));

export const Anchor = styled.a((props) => ({
  fontSize: props.$size || "1.7rem",
  fontWeight: props.$weight || 700,
  width: props.$width || "auto",
  padding: props.$padding || "1.5rem 3rem",
  backgroundColor: props.$backgroundColor || "var(--primaryColor)",
  color: props.$color || "var(--whiteColor) !important",
  borderRadius: props.$borderRadius || "6rem",
  outline: "none",
  border: props.$border || "0.1rem solid var(--primaryColor)",
  transition: "all 0.3s ease-in-out",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  "&:hover": {
    backgroundColor: "transparent",
    color: "var(--primaryColor) !important",
  },
}));

export const Input = styled.input(() => ({
  width: "100%",
  // height: "40px",
  fontSize: "1.6rem",
  padding: "0 1.5rem",
  borderRadius: "0.4rem",
  outline: "none",
  border: "2px solid var(--accentColor)",
  transition: "all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1)",
}));

export const Textarea = styled.textarea(() => ({
  width: "100%",
  fontSize: "1.6rem",
  padding: "0 0 0 1rem",
  borderRadius: "0.7rem",
  outline: "none",
  border: "0.1rem solid #e5e5e5",
  filter:
    "drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5))",
  transition: "all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1)",
  "&:focus": {
    border: "1px solid transparent",
    boxShadow: "0px 0px 0px 2px var(--primaryColor)",
    backgroundColor: "transparent",
  },
}));

export const Select = styled.select(() => ({
  width: "100%",
  height: "40px",
  fontSize: "1.6rem",
  padding: "0 0 0 1rem",
  borderRadius: "0.7rem",
  outline: "none",
  border: "0.1rem solid #e5e5e5",
  filter:
    "drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5))",
  transition: "all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1)",
  "&:focus": {
    border: "1px solid transparent",
    boxShadow: "0px 0px 0px 2px var(--primaryColor)",
    backgroundColor: "transparent",
  },
}));

export const Form = styled.form(() => ({
  // padding: "50px 40px 30px",
  // backgroundColor: "#ffffff",
  // boxShadow:
  //   "0px 106px 42px rgba(0, 0, 0, 0.01), 0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09), 0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
  // borderRadius: "11px",
  flexDirection: "column",
  // width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "3rem",
}));

export const Label = styled.label(() => ({
  display: "inline-block",
  width: "100%",
  fontSize: "1.6rem",
  color: "var(--secondaryColor)",
  fontWeight: 500,
}));
