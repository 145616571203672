import React, { useState, useEffect } from "react";
import { motion, easeInOut } from "framer-motion";
import styles from "./style.module.scss";
import {
  CartItem,
  CustomerForm,
  LogoContainer,
  ProductCardVariation,
  SkaBarBtn,
} from "../../components/ui";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import {
  Cart4,
  CupStraw,
  // MenuUp,
  PersonCircle,
} from "react-bootstrap-icons";
import { BASE_URL, productDemoData } from "../../utils/constants";
import successGif from "../../assets/success.gif";
import Footer from "../../components/parts/footer";
import { ToastContainer, toast } from "react-toastify";

const Home = () => {
  const [orderedList, setOrderedList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  // const [error, setError] = useState(false);

  const productHandler = (product, quantity) => {
    // Update cart item quantity if already in cart
    if (orderedList.some((item) => item.id === product.id)) {
      console.log("passed", quantity);
      setOrderedList((prev) =>
        prev.map((item) =>
          item.id === product.id
            ? {
                ...item,
                quantity: quantity,
                totalPrice: quantity * item.baseAmount,
              }
            : item
        )
      );
      return;
    }

    console.log("failed", quantity);
    // Add to cart
    setOrderedList((prev) => [
      ...prev,
      {
        ...product,
        quantity: quantity,
        totalPrice: quantity * product.baseAmount,
      }, // <-- initial amount 1
    ]);
  };

  useEffect(() => {
    if (orderedList.length > 0) {
      const price = orderedList.reduce(
        (total, item) => total + item.totalPrice,
        0
      );
      const gstPrice = price * 0.05;
      const totalPriceAfterGST = price + gstPrice;
      setTotalAmount(totalPriceAfterGST.toFixed(2));
    } else {
      setTotalAmount(0);
    }
  }, [orderedList]);

  function placeOrderHandler(customerData) {
    if (orderedList.length > 0) {
      setLoader(true);
      fetch(BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderedProduct: orderedList,
          totalAmount: totalAmount,
          ...customerData,
        }),
      })
        .then(function (data) {
          setLoader(false);
          if(!data.ok) {
            throw new Error("Error");
          }

          if(data.ok) {
            setSuccess(true);
          }
          console.log(data);
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Form not submitted!", {
            position: "top-center",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            style: { fontSize: "1.8rem", fontWeight: 500 },
          });
          console.log(error);
        });
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        setIsMenuActive(false);
      }, 5000);
    }
  }, [success, setSuccess]);

  return (
    <div className={styles.homePage}>
      <Container>
        <Row>
          <Col>
            <LogoContainer isMenuActive={isMenuActive} />
          </Col>
        </Row>
        {isMenuActive ? (
          <>
            <Row
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.8,
                duration: 3,
                easings: easeInOut,
                type: "spring",
              }}
              className={styles.pageContent}
            >
              {!success ? (
                <>
                  <Col lg={8}>
                    <SectionTitle className={styles.sectionTitle}>
                      <CupStraw size={30} /> BEVERAGES
                    </SectionTitle>
                    <div className={styles.beveragesContainer}>
                      {/* <SectionTitle className={styles.sectionSubTitle}>
                    <CupStraw size={25} /> BEVERAGES
                  </SectionTitle> */}
                      <div className={styles.innerCategoryBox}>
                        <SectionTitle className={styles.sectionInnerTitle}>
                          Zero Proof
                        </SectionTitle>
                        <div className={styles.foodMenuList}>
                          {productDemoData.map((val, i) => {
                            if (
                              val.categoryMeta.parent_category ===
                                "beverages" &&
                              val.categoryMeta.child_category === "zero proof"
                            ) {
                              return (
                                <ProductCardVariation
                                  name={val.name}
                                  baseAmount={val.baseAmount}
                                  description={val.description}
                                  productData={val}
                                  key={i}
                                  onaddToCart={(data, quantity) =>
                                    productHandler(data, quantity)
                                  }
                                />
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                      <div className={styles.innerCategoryBox}>
                        <SectionTitle className={styles.sectionInnerTitle}>
                          By Glass
                        </SectionTitle>
                        <div className={styles.foodMenuList}>
                          {productDemoData.map((val, i) => {
                            if (
                              val.categoryMeta.parent_category ===
                                "beverages" &&
                              val.categoryMeta.child_category === "by glass"
                            ) {
                              return (
                                <ProductCardVariation
                                  name={val.name}
                                  baseAmount={val.baseAmount}
                                  description={val.description}
                                  productData={val}
                                  key={i}
                                  onaddToCart={(data, quantity) =>
                                    productHandler(data, quantity)
                                  }
                                />
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                      <div className={styles.innerCategoryBox}>
                        <SectionTitle className={styles.sectionInnerTitle}>
                          By The Bottle
                        </SectionTitle>
                        <div className={styles.foodMenuList}>
                          {productDemoData.map((val, i) => {
                            if (
                              val.categoryMeta.parent_category ===
                                "beverages" &&
                              val.categoryMeta.child_category === "by bottle"
                            ) {
                              return (
                                <ProductCardVariation
                                  name={val.name}
                                  baseAmount={val.baseAmount}
                                  description={val.description}
                                  productData={val}
                                  key={i}
                                  onaddToCart={(data, quantity) =>
                                    productHandler(data, quantity)
                                  }
                                />
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>
                    <div className={styles.beveragesContainer}>
                      <SectionTitle className={styles.sectionTitle}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-cookie"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm4.5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                          <path d="M8 0a7.963 7.963 0 0 0-4.075 1.114c-.162.067-.31.162-.437.28A8 8 0 1 0 8 0Zm3.25 14.201a1.5 1.5 0 0 0-2.13.71A7.014 7.014 0 0 1 8 15a6.967 6.967 0 0 1-3.845-1.15 1.5 1.5 0 1 0-2.005-2.005A6.967 6.967 0 0 1 1 8c0-1.953.8-3.719 2.09-4.989a1.5 1.5 0 1 0 2.469-1.574A6.985 6.985 0 0 1 8 1c1.42 0 2.742.423 3.845 1.15a1.5 1.5 0 1 0 2.005 2.005A6.967 6.967 0 0 1 15 8c0 .596-.074 1.174-.214 1.727a1.5 1.5 0 1 0-1.025 2.25 7.033 7.033 0 0 1-2.51 2.224Z" />
                        </svg>{" "}
                        Bites
                      </SectionTitle>
                      <div className={styles.innerCategoryBox}>
                        <div className={styles.foodMenuList}>
                          {productDemoData.map((val, i) => {
                            if (val.categoryMeta.parent_category === "bites") {
                              return (
                                <ProductCardVariation
                                  name={val.name}
                                  baseAmount={val.baseAmount}
                                  description={val.description}
                                  productData={val}
                                  key={i}
                                  onaddToCart={(data, quantity) =>
                                    productHandler(data, quantity)
                                  }
                                />
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={styles.cartBox}>
                      <SectionTitle className={styles.sectionTitle}>
                        <Cart4 size={25} /> CART ITEMS
                      </SectionTitle>
                      <div className={styles.cartContent}>
                        {orderedList && orderedList.length > 0 ? (
                          orderedList.map((val, i) => (
                            <CartItem
                              productId={val.id}
                              name={val.name}
                              quantity={val.quantity}
                              baseAmount={val.baseAmount}
                              totalPrice={val.totalPrice}
                              orderedList={orderedList}
                              setOrderedList={setOrderedList}
                              key={i}
                            />
                          ))
                        ) : (
                          <Text $textAlign="center">No item added yet.</Text>
                        )}
                        <div className={styles.totalAmountContainer}>
                          <SectionTitle $color={"var(--accentColor)"}>
                            TOTAL AMOUNT
                            <br />
                            <span style={{ fontSize: "14px", color: "#fff" }}>
                              *Including 5% GST
                            </span>
                          </SectionTitle>
                          <Text>${totalAmount}</Text>
                        </div>
                      </div>
                      <SectionTitle
                        style={{ marginBottom: "3rem" }}
                        className={styles.sectionTitle}
                      >
                        <PersonCircle size={25} /> GUEST DETAILS
                      </SectionTitle>
                      <CustomerForm
                        onFormSubmit={placeOrderHandler}
                        isSubmitting={loader}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                <Col className={styles.successContainer}>
                  <div className={styles.successGif}>
                    <img src={successGif} alt="loader" />
                  </div>
                  <div>
                    <SectionTitle $textAlign="center">
                      Your order is placed successfully
                    </SectionTitle>
                  </div>
                </Col>
              )}
            </Row>
            <Footer />
          </>
        ) : (
          <Row>
            <Col
              style={{ textAlign: "center", marginTop: "3rem" }}
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 5, easings: easeInOut, type: "spring" }}
            >
              <SkaBarBtn onClick={() => setIsMenuActive(true)} />
            </Col>
          </Row>
        )}
      </Container>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Home;
