import React from 'react';
import styles from "./style.module.scss";
import { Plus } from 'react-bootstrap-icons';
import { Text } from '../../styledComponent';

const CartItem = (props) => {
    const handleRemove = (productCode) => {
        props.setOrderedList(cart => cart.filter(item => item.id !== productCode));
      };
    
  return (
    <div className={styles.cartItemContainer}>
        <button onClick={() => handleRemove(props.productId)}><Plus /></button>
        <div>
        <div>
        <Text>{props.name} (x{props.quantity})</Text>
        </div>
        <div>
        <Text>${props.totalPrice.toFixed(2)}</Text>
        </div>
        </div>
    </div>
  )
}

export default CartItem