// import demoImg from "../assets/demoProduct.jpg";

export const BASE_URL = "https://skafoodorder-be-a22a484f2e93.herokuapp.com/send"
// export const BASE_URL = "http://localhost:4000/send"

export const productDemoData = [
  {
    id: 1,
    name: "Sparkling Water",
    baseAmount: 4,
    totalPrice: 4,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "zero proof",
    },
  },
  {
    id: 2,
    name: "Assorted Juices",
    baseAmount: 4,
    totalPrice: 4,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "zero proof",
    },
  },
  {
    id: 3,
    name: "Zero Proof Cocktail & Beer",
    baseAmount: 6,
    totalPrice: 6,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "zero proof",
    },
  },
  {
    id: 4,
    name: "Nespresso Cappuccino | Americano| Iced",
    baseAmount: 5,
    totalPrice: 5,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "zero proof",
    },
  },
  {
    id: 5,
    name: "Prosecco | Sparkling Rose | Mimosa (6oz)",
    baseAmount: 8,
    totalPrice: 8,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "by glass",
    },
  },
  {
    id: 6,
    name: "House Wine Red | White | Rose (6oz)",
    baseAmount: 10,
    totalPrice: 10,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "by glass",
    },
  },
  {
    id: 7,
    name: "YYC Craft Beer (16oz)",
    baseAmount: 10,
    totalPrice: 10,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "by glass",
    },
  },
  {
    id: 8,
    name: "Feature Premix cocktail (1oz)",
    baseAmount: 8,
    totalPrice: 8,
    quantity: 0,
    description: "Caesar, Paloma, Margherita",
    categoryMeta: {
      parent_category: "beverages",
      child_category: "by glass",
    },
  },
  {
    id: 9,
    name: "Cocktail Concierge (2oz)",
    baseAmount: 15,
    totalPrice: 15,
    quantity: 0,
    description: "Smoked Walnut Old Fashioned, Wild rose vesper, Paper Plane, Cacao Boulevardier, Port of manhattan, Bobby Burns",
    categoryMeta: {
      parent_category: "beverages",
      child_category: "by glass",
    },
  },
  {
    id: 10,
    name: "Prosecco | Sparkling Rose",
    baseAmount: 50,
    totalPrice: 50,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "beverages",
      child_category: "by bottle",
    },
  },
  {
    id: 11,
    name: "Champagne",
    baseAmount: 120,
    totalPrice: 120,
    quantity: 0,
    description: "Please ask Server for availability",
    categoryMeta: {
      parent_category: "beverages",
      child_category: "by bottle",
    },
  },
  {
    id: 12,
    name: "House Cheese & Fruit Plate",
    baseAmount: 15,
    totalPrice: 15,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "bites",
      child_category: null,
    },
  },
  {
    id: 12,
    name: "Assorted Snacks",
    baseAmount: 3.5,
    totalPrice: 3.5,
    quantity: 0,
    description: null,
    categoryMeta: {
      parent_category: "bites",
      child_category: null,
    },
  },
];

// Format input in canada phone number format
export const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};
