import React from "react";
import "./style.scss";
import { ArrowRight } from "react-bootstrap-icons";

const SkaBarBtn = (props) => {
  return (
    <button onClick={() => props.onClick()} className="skaBtn">
      <span className="box">
        SKA BAR
        <ArrowRight />
      </span>
    </button>
  );
};

export default SkaBarBtn;
