import React from "react";
import { useFormik } from "formik";
import { Form, Label } from "../../styledComponent";
import { customerFormInitialValues } from "../../../utils/formik/initialvalue";
import { customerFormValidationSchema } from "../../../utils/formik/validationSchema";
import styles from "./style.module.scss";
import SecondaryLoader from "../secondaryLoader";

const CustomerForm = (props) => {
  const formik = useFormik({
    initialValues: customerFormInitialValues,
    validationSchema: customerFormValidationSchema,
    onSubmit: (values) => {
      console.log("contact form", values);
      props.onFormSubmit(values);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} className={styles.customerForm}>
      <div>
        <div>
          <Label htmlFor="firstName">First Name*</Label>
          <input
            type="text"
            id="firstName"
            {...formik.getFieldProps("firstName")}
            style={
              formik.touched.firstName &&
              formik.errors.firstName && {
                borderColor: "red",
              }
            }
          />
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="lastName">Last Name*</Label>
          <input
            type="text"
            id="lastName"
            {...formik.getFieldProps("lastName")}
            style={
              formik.touched.lastName &&
              formik.errors.lastName && {
                borderColor: "red",
              }
            }
          />
        </div>
      </div>
      <div>
        <Label htmlFor="tableNumber">Table Number*</Label>
        <input
          type="text"
          id="tableNumber"
          {...formik.getFieldProps("tableNumber")}
          style={
            formik.touched.tableNumber &&
            formik.errors.tableNumber && {
              borderColor: "red",
            }
          }
        />
      </div>
      <button
        type="submit"
        className={!formik.isValid ? "disabledBtn" : "submitBtn"}
      >
        {props.isSubmitting ? <SecondaryLoader /> : "Place Order"}
      </button>
    </Form>
  );
};

export default CustomerForm;
