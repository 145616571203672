import React, { useState } from "react";
import styles from "./style.module.scss";
import QuanityController from "../quantityController";
import { CartPlus } from "react-bootstrap-icons";
import { Text } from "../../styledComponent";

const ProductCardVariation = (props) => {
  const [productQuantity, setProductQuantity] = useState(0);
  // console.log("Product Data", props.productData.id);
  return (
    <div className={styles.productContainer}>
      <div className={styles.productInfo}>
        <h3>{props.name}</h3>
        {props.description && <p>{props.description}</p>}
      </div>
      <div className={styles.productPrice}><Text>${props.baseAmount}</Text></div>
      {props.productData && (
        <div className={styles.addCartBlock}>
          <QuanityController
            currentQuantity={(val) => setProductQuantity(val)}
            productQuantity={props.productData.quantity}
            productId={props.productData.id}
          />
          <div className={styles.addToCartBtn}>
            <button
              onClick={() =>
                productQuantity > 0
                  ? props.onaddToCart(props.productData, productQuantity)
                  : null
              }
            >
              <CartPlus />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCardVariation;
