import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footerLogo from "../../../assets/skaFooterLogo.png";
import styles from "./style.module.scss";
import { Text } from "../../styledComponent";

const Footer = () => {
  return (
    <Container className={styles.footerContainer}>
      <Row>
        <Col xs={12} lg={6}>
          <img src={footerLogo} alt="SKA" />
        </Col>
        <Col xs={12} lg={6}>
          <Text>
            Website Design and Developed by{" "}
            <a
              href="https://x360digital.com/"
              target="_blank"
              rel="noReferrer"
            >
              x360 Digital Inc.
            </a>
          </Text>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
