import React from "react";
import logo from "../../../assets/logo.png";
import { motion, easeInOut } from "framer-motion";
import styles from "./style.module.scss";

const LogoContainer = (props) => {
  const initial = {
    maxWidth: "70rem",
  };

  const final = {
    maxWidth: window.innerWidth <= 600 ? "30rem" : "40rem",
    transitionEnd: {
      maxWidth: window.innerWidth <= 600 ? "30rem" : "40rem",
    },
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 5, easings: easeInOut, type: "spring" }}
      className={styles.logoContainer}
    >
      <motion.img
        animate={!props.isMenuActive ? initial : final}
        transition={{ duration: 1, easings: easeInOut }}
        src={logo}
        alt="SKA Thermal Spa"
      />
    </motion.div>
  );
};

export default LogoContainer;
